<template>
  <div class="he-ui-scanning-results bg-white d-flex align-items-center flex-column">
    <div class="he-ui-preview-container">
      <preview
        class="he-ui-preview"
        :image="manageFingerprintImage"
      />
    </div>
    <div class="he-ui-scanning-buttons-container d-flex flex-column align-items-center">
      <div class="he-ui-font-regular mb-3">
        {{ getPrimaryLabel }}
      </div>
      <Button
        v-if="!isAnyFingerprintResultImage"
        class="mb-3"
        template="blue"
        rounded
        uppercase
        :text="$t('main.scan')"
        :onClick="onScan" />
      <div class="he-ui-font-regular mt-4 mb-3">
        {{ getSecondaryLabel }}
      </div>
      <div class="d-flex gap-3">
        <Button
          template="white"
          rounded
          uppercase
          :text="isAnyFingerprintResultImage ? $t('main.replace') : $t('main.manual')"
          :onClick="isAnyFingerprintResultImage ? onReplace : navigateToManualScan" />
        <Button
          v-if="isAnyFingerprintResultImage"
          class="ms-4"
          template="blue"
          rounded
          uppercase
          :disabled="disableAcceptButton"
          :text="$t('main.accept')"
          :onClick="onAccept" />
      </div>
    </div>
    <hr>
    <div class="he-ui-info-scanning-container">
       <info-box
        :status="validateStatus"
        :hand="hand"
        :finger="finger"
       />
    </div>
  </div>
  <portal to="modal-place">
    <Scanning
      v-if="loading"
      class="he-ui-scanning-modal"/>
  </portal>
  <portal to="modal-place">
    <SuccesModal
      v-if="showSuccessModal"
      :patientId="patientId"
      showButton="true"
      :message="$t('main.accept')"
      :onClicked="closeSuccessModal"/>
  </portal>
  <portal to="modal-place">
    <ErrorModal
      v-if="showErrorModal"
      :title="$t('main.error')"
      :subtitle="$t('main.fingerprint_not_found')"
      image="patient-error"
      :buttons="manageErrorModalButtons"
      @close="() => showModal = false"/>
  </portal>
  <portal to="modal-place">
    <AlreadyExistsModal
      v-if="showAlreadyRegisteredFingerprintModal"
      :onClicked="closeAlreadyExistsModal"
      @close="() => showAlreadyRegisteredFingerprintModal = false"/>
  </portal>
</template>

<script>
import { FINGERS_RIGHT_HAND, HANDS, HHID, IS_NEW_PATIENT } from '@/constants/constants'
import { MAIN_PAGE_ROUTE_NAME, SCAN_MULTIFINGER_PAGE_ROUTE_NAME } from '@/router/routes'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AlreadyExistsModal from '@/components/AlreadyExistsModal'
import Button from '@/components/MainButton'
import ErrorModal from '@/components/ErrorModal'
import InfoBox from '@/components/InfoBox'
import Preview from '@/components/Preview'
import Scanning from '@/components/Scanning'
import SuccesModal from '@/components/SuccesModal'
import fingerprintReader from '@/composition/fingerprintReader'
import mixins from '@/composition/mixins'

export default {
  components: {
    AlreadyExistsModal,
    Button,
    ErrorModal,
    InfoBox,
    Preview,
    Scanning,
    SuccesModal
  },
  props: {
    onMultipleFingersPage: {
      type: Boolean,
      default: false
    },
    onRegistering: {
      type: Boolean,
      default: false
    },
    hand: {
      type: Number,
      default: 0
    },
    finger: {
      type: Number,
      default: 0
    },
    fingerprintImage: {
      type: String,
      default: null
    },
    saveDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const { clean, hasResultImage, imageData, isLowQuality, resetFingerprint, stopCapture } = fingerprintReader()
    const { sendDataToParentClient } = mixins()

    return {
      clean,
      hasResultImage,
      imageData,
      isLowQuality,
      resetFingerprint,
      sendDataToParentClient,
      stopCapture
    }
  },
  data () {
    return {
      FINGERS_RIGHT_HAND,
      HANDS,
      backSavedFingers: [],
      loading: false,
      patientId: localStorage.getItem(HHID) ? localStorage.getItem(HHID) : null,
      showAlreadyRegisteredFingerprintModal: false,
      showErrorModal: false,
      showSuccessModal: false,
      OK_MESSAGE: 'OK'
    }
  },
  computed: {
    getPrimaryLabel () {
      return !this.isAnyFingerprintResultImage ? this.$t('main.identify_patient_scanning_title') + ':' : ''
    },
    getSecondaryLabel () {
      return !this.isAnyFingerprintResultImage ? this.$t('main.if_not_possible') + ':' : this.$t('main.is_fingerprint_valid')
    },
    manageFingerprintImage () {
      return this.isPropFingerprintImage ? this.fingerprintImage : this.imageData
    },
    manageErrorModalButtons () {
      return (this.onMultipleFingersPage)
        ? [
          { text: this.$t('main.try_again'), template: 'white', handler: () => { this.showErrorModal = false; this.onScan() } },
          { text: this.$t('main.new_patient'), template: 'blue', handler: this.onNewPatient }
        ]
        : [{ text: this.$t('main.try_again'), template: 'blue', handler: this.onTryAgain }]
    },
    validateStatus () {
      return !this.isAnyFingerprintResultImage ? 'initial' : 'finished'
    },
    isResultImage () {
      return this.hasResultImage
    },
    isPropFingerprintImage () {
      return this.fingerprintImage !== null
    },
    isAnyFingerprintResultImage () {
      return this.isResultImage || this.isPropFingerprintImage
    },
    disableAcceptButton () {
      if ((!this.saveDisabled) && !(this.isLowQuality && localStorage.getItem(IS_NEW_PATIENT))) {
        this.$emit('imageCaptured', true)
      }
      return (this.saveDisabled) || (this.isLowQuality && localStorage.getItem(IS_NEW_PATIENT))
    },
    ...mapGetters([
      'getAllSensors'
    ])
  },
  methods: {
    ...mapActions([
      'createPatient',
      'fetchPatientFingerprints',
      'loginPatient',
      'registerFingerprint'
    ]),
    ...mapMutations([
      'resetPatientId'
    ]),
    navigateToManualScan () {
      this.$router.push({
        name: SCAN_MULTIFINGER_PAGE_ROUTE_NAME
      })
    },
    navigateToOwnMultifingerReset (savedFingers = null) {
      this.$emit('navigateToMultifinger', savedFingers)
    },
    onScan () {
      this.resetFingerprint()
      this.$emit('scan')
    },
    onAccept () {
      if (this.onRegistering) {
        this.onRegisterFingerprint()
      } else {
        this.loading = true
        const fingerprintPicture = this.manageFingerprintImage
        const data = {
          sensorId: this.getAllSensors[0].id,
          fingerprintBase64: this.clean(fingerprintPicture),
          fingerNumber: this.finger
        }
        this.loginPatient(data)
          .then((response) => {
            this.patientId = response.hhid
            this.sendDataToParentClient(this.patientId)
            this.showSuccessModal = true
          })
          .catch(() => {
            this.showErrorModal = true
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onReplace () {
      this.onScan()
    },
    onTryAgain () {
      this.$router.push({
        name: SCAN_MULTIFINGER_PAGE_ROUTE_NAME
      })
    },
    onRegisterFingerprint () {
      this.loading = true
      const data = {
        patientId: this.patientId,
        sensorId: this.getAllSensors[0].id,
        fingerprintBase64: this.clean(this.manageFingerprintImage),
        fingerNumber: this.finger
      }
      this.registerFingerprint(data)
        .then((response) => {
          this.retrieveFingerprintsForPatient()
        })
        .catch((e) => {
          this.showAlreadyRegisteredFingerprintModal = true
          this.scanning = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    retrieveFingerprintsForPatient () {
      if (!this.patientId) return
      this.fetchPatientFingerprints({ patientId: this.patientId })
        .then((response) => {
          this.backSavedFingers = response.map(i => i.fingerNumber)
          this.navigateToOwnMultifingerReset(this.backSavedFingers)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onNewPatient () {
      this.showErrorModal = false
      this.createPatient()
        .then((response) => {
          this.patientId = response.id
          this.stopCapture()
          localStorage.setItem(HHID, response.id)
          localStorage.setItem(IS_NEW_PATIENT, true)
          this.navigateToOwnMultifingerReset(this.backSavedFingers)
        })
    },
    closeSuccessModal () {
      this.canLeavePage = true
      this.showLeavingModal = false
      this.showSuccessModal = false
      this.resetPatientId()
      this.$router.push({
        name: MAIN_PAGE_ROUTE_NAME
      })
    },
    closeAlreadyExistsModal () {
      this.showAlreadyRegisteredFingerprintModal = false
      this.retrieveFingerprintsForPatient()
    }
  }
}
</script>

<style lang="scss">
.he-ui-fingerprint-preview {
  @media (max-height: 810px) {
    width: 242px !important;
    height: 242px !important;
  }
}
</style>
<style lang="scss" scoped>
hr {
  color: $grey-dark-color;
  width: 100%;
  margin-bottom: 0px !important;
}
.he-ui-preview-container {
  width: 242px;
  height: 242px;
  position: relative;
  top: -121px;
}
.he-ui-scanning-buttons-container {
  position: relative;
  top: -60px;
}
.he-ui-fingerprint-preview {
  width: 242px !important;
  height: 242px !important;
  @media (max-height: 810px) {
    width: 100px !important;
    height: 100px !important;
    background: greenyellow;
  }
}
.he-ui-info-scanning-container {
  margin: 40px 0px;
}
.he-ui-scanning-results {
  // padding: 76px 116px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  // margin-right: 100px;
  margin-right: 5%;
}
</style>
